<template>
  <div class="flex flex-col">
    <div class="flex w-full my-1"
      :class="[arrangeLabelAndInputVertically? 'flex-col items-start justify-center' : 'flex-row items-center justify-start']">
      <label :for="passwordFieldName" :class="[labelStyleClasses, arrangeLabelAndInputVertically? 'my-1.5' : 'mr-2','text-sm']">{{
        labelText
      }}</label>
      <div class="flex flex-row w-full items-center">
        <input :value="password" :type="visiblePassword
          ? 'text'
          : 'password'
          " :name="passwordFieldName" :class="[
    'h-10 border  border-r-0 rounded-l px-2 focus:outline-none w-full',
    passwordErrors.length > 0
      ? 'border-a-error'
      : 'border-a-neutral-dim hover:border-a-neutral-lightest focus:border-a-primary',
  ]" @input="emitPasswordInput" @mouseover="
  passwordFieldHovered = true
  " @mouseleave="
    passwordFieldHovered = false
    " @focus="
    passwordFieldFocused = true
    " @blur="($event) => onPasswordBlur($event)" />
        <p :class="[
          'flex flex-row items-center justify-center text-sm border border-l-0 rounded-r h-10 cursor-pointer px-2 py-auto text-a-neutral-dark text-a-secondary',
          passwordErrors.length > 0
            ? 'border-a-error'
            : passwordFieldFocused
              ? 'border-a-primary'
              : passwordFieldHovered
                ? 'border-a-neutral-lightest'
                : 'border-a-neutral-dim',
          ,
        ]" @click="
  visiblePassword =
  !visiblePassword
  ">
          {{
            visiblePassword
            ? "Hide"
            : "Show"
          }}
        </p>
        <template v-if="showErrorIcon">
          <span v-show="passwordErrors.length > 0">
            <Icon  name="ic:outline-error" class="text-lg text-a-error mx-2"/>
          </span>
        </template>
      </div>
    </div>
    <div class="flex flex-row">
      <span class="text-a-error text-sm flex flex-row items-center">
        {{ passwordErrors[0] }}</span>
    </div>
    <div class="my-2" v-if="isConfirmable">
      <div class="flex flex-row w-full">
        <input :value="passwordConfirmation" :name="`${passwordFieldName}-confirmation`" :class="[
          'h-10 border rounded-l px-2 focus:outline-none w-full',
          passwordConfirmationErrors.length > 0
            ? 'border-a-error'
            : 'border-a-neutral-dim hover:border-a-neutral-lightest focus:border-a-primary',
        ]" :type="visiblePassword
  ? 'text'
  : 'password'
  " placeholder="Re-Type Password" @input="emitPasswordConfirmationInput
    " @mouseover="
    passwordConfirmationFieldHovered = true
    " @mouseleave="
    passwordConfirmationFieldHovered = false
    " @focus="
    passwordConfirmationFieldFocused = true
    " @blur="($event) => onPasswordConfirmationBlur($event)" />
        <Icon v-if="showErrorIcon" name="ic:outline-error" class="text-lg text-a-error mx-2"
          v-show="passwordConfirmationErrors.length > 0" />
      </div>
      <span class="text-a-error text-xs md:text-sm flex flex-row items-center">
        {{ passwordConfirmationErrors[0] }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
const visiblePassword = ref(false);
const passwordFieldFocused = ref(false);
const passwordConfirmationFieldFocused =
  ref(false);
const passwordFieldHovered = ref(false);
const passwordConfirmationFieldHovered =
  ref(false);

const emit = defineEmits([
  "update:password",
  "update:password-confirmation",
]);

const props = withDefaults(defineProps<{
  password: string | number;
  passwordConfirmation?: string | number;
  passwordFieldName: string;
  validationLabel: string;
  validationRules?: string;
  tailwindClasses?: string;
  showErrorIcon?: boolean;
  isConfirmable?: boolean;
  labelText?: string;
  labelStyleClasses?: string;
  arrangeLabelAndInputVertically?: boolean;

}>(), {
  validationRules: "",
  tailwindClasses: "",
  showErrorIcon: true,
  isConfirmable: false,
  labelText: "",
  labelStyleClasses: "",
  arrangeLabelAndInputVertically: false,
});

const passwordConfirmationFieldName = computed(() => {
  return `${props.passwordFieldName}-confirmation`;
});

const passwordConfirmationFieldLabel = computed(() => {
  return `${props.labelText} Confirmation`;
})


const { errors: passwordErrors, value: passwordValue, validate: validatePassword, handleBlur: handlePasswordBlur } = useField(
  () => props.passwordFieldName,
  props.validationRules,
  { syncVModel: true, label: props.labelText }
)

function onPasswordBlur(event: Event) {
  handlePasswordBlur(event, true)
  passwordFieldFocused.value = false
}


function emitPasswordInput(event: Event) {
  const element = event.target as HTMLInputElement;
  emit(
    "update:password",
    element.value
  );
  passwordValue.value = element.value;
  validatePassword()
}


const { errors: passwordConfirmationErrors, value: passwordConfirmationValue, validate: validatePasswordConfirmation, handleBlur: handlePasswordConfirmationBlur } = useField(
  () => passwordConfirmationFieldName.value,
  props.isConfirmable?`confirmed:@${props.passwordFieldName}`:'',
  { syncVModel: true, label: passwordConfirmationFieldLabel.value }
)

function onPasswordConfirmationBlur(event: Event) {
  handlePasswordConfirmationBlur(event, true)
  passwordConfirmationFieldFocused.value = false
}

function emitPasswordConfirmationInput(event: Event) {
  const element = event.target as HTMLInputElement;
  emit(
    "update:password-confirmation",
    element.value
  );
  passwordConfirmationValue.value = element.value;
  validatePasswordConfirmation();
}



</script>
